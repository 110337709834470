<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="profile"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Perfil"
        label-for="idProfile"
      >
        <v-select
          id="idProfile"
          v-model="profile"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="id"
          :options="dataOptions"
          :placeholder="'Perfil'"
          :disabled="readonly"
          :clearable="!readonly"
          @open="onCreate()"
        >
          <template #option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #selected-option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #no-options>
            Não há opções de perfis cadastrados.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BFormGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import Fuse from 'fuse.js'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'profileValue',
    event: 'profileChange',
  },
  props: {
    profileValue: {
      type: [Object, String],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const dataOptions = []

    return {
      dataOptions,
      required,
    }
  },
  computed: {
    profile: {
      get() {
        return this.profileValue
      },
      set(value) {
        this.$emit('profileChange', value)
      },
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .permission
        .fetchProfilePermissionList()
        .then(response => {
          this.dataOptions = response.data.results
          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['code', 'name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
