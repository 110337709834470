<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <b-form
          class="mt-1"
        >
          <b-row class="justify-content-center">
            <b-col
              v-if="!isCreateMode"
              cols="12"
              md="4"
              lg="3"
            >
              <b-card no-body>
                <b-row class="mt-4">
                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col>
                        <div class="d-flex justify-content-center">
                          <div
                            id="idUserIcon"
                          >
                            <input
                              id="add_image"
                              ref="fileInput"
                              type="file"
                              class="d-none"
                              accept="image/*"
                              @change="handleImage"
                            >
                            <div
                              v-if="userImage == ''"
                              id="emptyUserPhoto"
                              class=" d-flex align-items-center justify-content-center"
                              @click="selectImage()"
                            >
                              <p class="m-0">
                                Adicionar foto
                              </p>
                            </div>

                            <b-avatar
                              :src="userImage"
                              :variant="`light-success`"
                              :class="(userImage == '') ? 'd-none': ''"
                              size="10rem"
                              rounded
                            />
                            <div
                              id="idUserTrashBtn"
                              :class="((userImage == '') || (isViewMode)) ? 'd-none': ''"
                            >
                              <b-button
                                id="btnDeletePhoto"
                                variant="outline-info"
                                class="btn-icon rounded-circle"
                                size="sm"
                                @click="deletePhoto()"
                              >
                                <svg-icon
                                  type="mdi"
                                  size="18"
                                  :path="mdiTrashCanOutline"
                                />
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row
                      class="pt-1 text-center"
                      align-v="center"
                    >
                      <b-col
                        cols="12"
                        class="px-1"
                      >
                        <p class="font-weight-bolder mb-0 px-1">
                          {{ user.first_name }}
                        </p>
                        <p class="mb-0">
                          {{ user.permission_set.name }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <hr class="mt-2 mb-2">
                <b-row class="ml-2">
                  <b-col
                    cols="12"
                  >
                    <b-row
                      class="mt-1"
                    >
                      <b-col>
                        <p class="font-weight-bolder d-inline">
                          LOGIN:
                        </p>
                        <span> {{ user.get_email }}</span>
                      </b-col>
                    </b-row>
                    <b-row
                      class="mt-1"
                    >
                      <b-col>
                        <p class="font-weight-bolder d-inline">
                          SITUAÇÃO:
                        </p>
                        <b-badge
                          pill
                          :variant="`light-${resolveUserStatusVariant(user.is_active)}`"
                          class="text-capitalize"
                        >
                          {{ resolveUserStatusLabel(user.is_active) }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <hr class="mt-2 mb-2">
                <b-row
                  class="mb-2"
                >
                  <b-col
                    class="d-flex justify-content-center buttonsSaveCancel"
                  >
                    <b-button
                      v-if="!isViewMode"
                      id="btn_save_user"
                      variant="primary"
                      class="mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      :disable="invalid"
                      @click="handleSubmit(onSubmit)"
                    >
                      Salvar
                    </b-button>
                    <b-button
                      v-if="!isViewMode && !isCreateMode"
                      id="btn_cancel"
                      variant="outline-primary"
                      class="mr-0 mr-sm-1"
                      @click="disableEdition"
                    >
                      Cancelar
                    </b-button>
                    <b-button
                      v-if="!isViewMode && isCreateMode"
                      id="btn_cancel"
                      variant="outline-primary"
                      class="mr-0 mr-sm-1"
                      :to="{ name: 'user' }"
                    >
                      Cancelar
                    </b-button>
                    <b-button
                      v-if="isViewMode"
                      id="btn-user"
                      type="submit"
                      variant="primary"
                      class="mr-0 mr-sm-1"
                      @click="enableEdition"
                    >
                      Editar
                    </b-button>
                    <b-button
                      v-if="isViewMode"
                      id="btn_back"
                      variant="outline-primary"
                      class="mr-0 mr-sm-1"
                      :to="{ name: 'user' }"
                    >
                      Voltar
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="8"
              lg="9"
            >
              <b-card no-body>
                <b-card-title
                  class="mt-2 ml-2 mb-0"
                >
                  <feather-icon
                    icon="InfoIcon"
                    size="21"
                    class="mr-50"
                  />
                  <span class="d-inline color-primary">Perfil</span>
                </b-card-title>
                <hr>
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <validation-provider
                            #default="validationContext"
                            name="first_name"
                            rules="required"
                          >
                            <b-form-group
                              label="Nome"
                              label-for="first-name"
                            >
                              <b-form-input
                                id="first_name"
                                v-model="user.first_name"
                                :state="isViewMode ? null : getValidationState(validationContext)"
                                name="first_name"
                                :placeholder="isViewMode ? '' : 'Nome'"
                                :readonly="isViewMode"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <validation-provider
                            #default="validationContext"
                            name="last_name"
                            rules="required"
                          >
                            <b-form-group
                              label="Sobrenome"
                              label-for="last_name"
                            >
                              <b-form-input
                                id="last_name"
                                v-model="user.last_name"
                                :state="isViewMode ? null : getValidationState(validationContext)"
                                :placeholder="isViewMode ? '' : 'Sobrenome'"
                                :readonly="isViewMode"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <validation-provider
                            #default="validationContext"
                            name="phone_number"
                            :rules="{
                              required: true,
                              min: 14,
                            }"
                          >
                            <b-form-group
                              label="Telefone"
                              label-for="phone_number"
                            >
                              <b-form-input
                                id="phone_number"
                                v-model="user.phone_number"
                                v-mask="getMask('phone_number')"
                                :state="isViewMode ? null : getValidationState(validationContext)"
                                :placeholder="isViewMode ? '' : '(19) 99999-9999'"
                                :readonly="isViewMode"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          v-if="isCreateMode"
                          cols="12"
                          md="6"
                        >
                          <validation-provider
                            ref="userEmail"
                            #default="validationContext"
                            name="email"
                            rules="required|email"
                          >
                            <b-form-group
                              label="Email"
                              label-for="email"
                            >
                              <b-form-input
                                id="email"
                                v-model="user.get_email"
                                :state="isViewMode ? null : getValidationState(validationContext)"
                                :placeholder="isViewMode ? '' : 'Email'"
                                :readonly="!isCreateMode"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <permission-select
                            id="idProfileSelect"
                            v-model="user.permission_set"
                            :readonly="isViewMode"
                            :required-field="true"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-card
                v-if="isCreateMode"
                no-body
              >
                <b-card-title
                  class="mt-2 ml-2 mb-0"
                >
                  <svg-icon
                    type="mdi"
                    :path="mdiLockOutline"
                  />
                  <span class="d-inline ml-50">Senha</span>
                </b-card-title>
                <hr>
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <label>Nova senha</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required|password"
                          name="password"
                          vid="password"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="account-new-password"
                              v-model="newPasswordValue"
                              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                              :type="passwordFieldTypeNew"
                              name="new-password"
                              placeholder="Nova Senha"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIconNew"
                                class="cursor-pointer"
                                @click="togglePasswordNew"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--Repeat password must match  -->
                    <b-col md="6">
                      <b-form-group>
                        <label>Confirme a nova senha</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required|confirmed:password"
                          name="retype_password"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="account-retype-new-password"
                              v-model="retypePassword"
                              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                              :type="passwordFieldTypeRetype"
                              name="retype-password"
                              placeholder="Repita a Nova Senha"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIconRetype"
                                class="cursor-pointer"
                                @click="togglePasswordRetype"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <address-edit-card
                :address="user"
                :is-view-mode="isViewMode"
                :required-fields="requiredFields"
              />
              <b-row v-if="hasPermissionToViewCommission()">
                <b-col
                  cols="12"
                  md="12"
                  lg="12"
                >
                  <b-card no-body>
                    <b-card-title
                      class="mt-2 ml-2 mb-0"
                    >
                      <svg-icon
                        type="mdi"
                        size="29"
                        :path="mdiCash"
                      />
                      <span class="d-inline color-primary ml-50">Comissão</span>
                    </b-card-title>
                    <hr>
                    <b-card-body
                      class="pt-1"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <cleave-customized
                            v-model="user.commission"
                            :option="options.percentage"
                            :places="2"
                            :label="'% do comissionamento'"
                            :readonly="isViewMode || !hasPermissionToUpdateCommission()"
                            :label-class="'formLabel'"
                            :label-prepend="null"
                            :label-append="'%'"
                          />
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
              <b-row
                v-if="isCreateMode"
                class="mb-2"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    id="btn_save_user"
                    variant="primary"
                    class="mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disable="invalid"
                    @click="handleSubmit(onSubmit)"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="btn_cancel"
                    variant="outline-primary"
                    class="mb-sm-0 mr-0 mr-sm-1"
                    :to="{ name: 'user' }"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-row>
        <b-col
          cols="12"
          md="4"
          lg="3"
        />
        <b-col
          cols="12"
          md="8"
          lg="9"
        >
          <user-edit-tab-password
            v-if="!isViewMode && !isCreateMode"
            ref="passwordBox"
            class="mt-2 pt-75"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BCardBody,
  BCardTitle,
  BButton,
  BAvatar,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed, password,
} from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import SvgIcon from '@jamescoyle/vue-icon'
import {
  mdiLockOutline,
  mdiTrashCanOutline,
  mdiCash,
} from '@mdi/js'
import CleaveCustomized from '@/components/utils/CleaveCustomized.vue'
import router from '@/router'
import syslic from '@/syslic'
import UserEditTabPassword from './UserEditTabPassword.vue'
import AddressEditCard from '@/components/address/AddressEditCard.vue'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import PermissionSelect from '@/components/permission/PermissionSelect.vue'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardTitle,
    BButton,
    BAvatar,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    SvgIcon,
    CleaveCustomized,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // Mask
    UserEditTabPassword,
    AddressEditCard,
    SpinLoader,
    PermissionSelect,
  },
  data() {
    let isViewMode = true
    if (router.currentRoute.path.match(/.*\/edit[/]*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    let isProfileMode = false
    let isEditMode = false
    let isCreateMode = false
    let userId = router.currentRoute.params.id
    let user = {
      address_type: '',
      address_type_set: '',
      country: 1,
      country_set: {
        id: 1,
        name: 'Brasil',
        code: '1058',
      },
      state_set: '',
      city_set: '',
      permission_set: '',
      commission: '',
    }
    let userImage = ''

    if (router.currentRoute.path.match(/.*\/me/)) {
      user = syslic.authentication.getProfile()
      userImage = syslic.authentication.getProfilePhoto()
      isProfileMode = true
      userId = user.get_uuid
      isEditMode = true
    } else if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    } else if (router.currentRoute.params.id === user.get_uuid) {
      if (isViewMode) {
        router.push({ name: 'profile-detail' })
      } else {
        router.push({ name: 'profile-edit' })
      }
    } else {
      isEditMode = true
    }

    const profile = syslic.authentication.getProfile()

    const requiredFields = []

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.user)

    const isLoaded = false

    const options = {
      percentage: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
    }

    return {
      required,
      confirmed,
      password,
      isViewMode,
      isProfileMode,
      isEditMode,
      isCreateMode,
      profile,
      requiredFields,
      user,
      userId,
      userImage,
      newPasswordValue: '',
      retypePassword: '',
      text_error: '',
      editProfile: true,
      changePassword: false,
      isPhotoUpdated: false,
      isPhotoDeleted: false,
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      refFormObserver,
      getValidationState,
      isLoaded,
      options,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.user.can_edit
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    'user.permission_set': function updateProfile(val) {
      if (val) {
        this.user.permission = val.id
      } else {
        this.user.permission = ''
      }
    },
  },
  created() {
    if (this.isProfileMode) {
      this.isLoaded = true
    } else if (this.isEditMode) {
      syslic
        .user
        .fetchUser(this.userId)
        .then(response => {
          this.user = response.data
          return syslic.user.getUserImage(this.userId)
        })
        .then(response => {
          if (response !== 'data:image/jpg;base64,') {
            this.userImage = response
          }
          this.isLoaded = true
        })
        .catch(error => {
          if (error.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.isLoaded = true
        })
    } else {
      this.isLoaded = true
    }
  },
  methods: {
    getMask(field) {
      let mask = '(##) ####-####'
      if (this.user[field]) {
        if (this.user[field].length > 14) {
          mask = '(##) #####-####'
        }
      }

      return mask
    },
    hasPermissionToViewCommission() {
      return this.profile.get_permissions_for_modules.commission.can_read
    },
    hasPermissionToUpdateCommission() {
      return this.profile.get_permissions_for_modules.commission.can_edit
    },
    createUser() {
      const userData = {
        email: '',
        is_active: true,
        password: '',
        profile: {
          first_name: '',
          last_name: '',
          bio: '',
          phone_number: '',
          address: '',
          number: '',
          neighborhood: '',
          complement: '',
          state: '',
          city: '',
          country: '',
          zip_code: '',
          permission: '',
        },
      }
      userData.email = this.user.get_email
      userData.password = this.newPasswordValue
      userData.profile = this.user

      syslic
        .user
        .addUser(userData)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Usuário adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.userId = response.data.uuid
          this.disableEdition()
        })
        .catch(error => {
          this.text_error = 'Não foi possível adicionar o usuário, por favor entre em contato com o administrador do sistema.'
          if (error.data.email[0] === 'user with this email address already exists.') {
            this.$refs.userEmail.applyResult({
              errors: ['Usuário com este Email já existe.'],
              valid: false,
              failedRules: {},
            })
            this.text_error = 'Usuário com este email já existe.'
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar um novo usuário.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updateUser() {
      this.$refs.passwordBox.updatePassword()
        .then(() => {
          syslic.user.updateUser(this.userId, this.user)
        })
        .then(() => {
          let promise = new Promise(resolve => { resolve() })

          if (this.isProfileMode) {
            promise = syslic.authentication.loadProfile()
          }

          return promise
        })
        .then(() => {
          let promise = new Promise(resolve => { resolve() })

          if (this.isPhotoDeleted) {
            promise = syslic
              .user
              .deleteUserImage(this.userId)
          } else if (this.isPhotoUpdated) {
            promise = syslic
              .user
              .updateUserImage(this.userId, this.userImage)
          }

          return promise
        })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Usuário atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          if (this.isProfileMode) {
            syslic.authentication.loadProfile()
          }

          this.disableEdition()
        })
        .catch(() => {
          this.$refs.refFormPassword.validate()
            .then(passwordValid => {
              if (passwordValid) {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Falha ao atualizar o usuário.',
                    text:
                      'Não foi possível atualizar o usuário, por favor entre em contato com o administrador do sistema.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    timeout: false,
                  },
                })
              }
            })
        })
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.createUser()
      } else if (this.isEditMode) {
        this.updateUser()
      }
    },
    enableEdition() {
      this.isViewMode = false

      if (this.isProfileMode) {
        router.push({ name: 'profile-edit' })
      } else {
        router.push({ name: 'user-edit', params: { id: this.userId } })
      }
    },
    disableEdition() {
      this.isViewMode = true

      if (this.isProfileMode) {
        router.push({ name: 'profile-detail' })
      } else {
        router.push({ name: 'user-detail', params: { id: this.userId } })
      }
    },
    showProfile() {
      this.editProfile = true
      this.changePassword = false
    },
    showChangePassword() {
      this.editProfile = false
      this.changePassword = true
    },
    handleImage(event) {
      let selectedFile = event.target.files[0]
      if (selectedFile.size > 5000000) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao atualizar foto.',
            text:
              'A foto deve ter tamanho de até 5MB',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            timeout: false,
          },
        })
        selectedFile = this.userImage
        return
      }
      const allowedExtensions = /(jpg|jpeg|png|gif)/i
      if (!allowedExtensions.exec(selectedFile.type)) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao atualizar foto.',
            text:
              'A foto deve ter o formato JPG, GIF ou PNG, com tamanho de até 5MB',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            timeout: false,
          },
        })
        selectedFile = this.userImage
        return
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.userImage = reader.result
        this.isPhotoUpdated = true
        this.isPhotoDeleted = false
      }
      reader.readAsDataURL(selectedFile)
    },
    deletePhoto() {
      this.isPhotoDeleted = true
      this.userImage = ''
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    resolveUserStatusVariant(status) {
      if (status === true || status === 'True') return 'success'
      if (status === false || status === 'False') return 'secondary'
      return 'success'
    },
    resolveUserStatusLabel(status) {
      if (status === true || status === 'True') return 'Ativo'
      if (status === false || status === 'False') return 'Inativo'
      return 'Ativo'
    },
    selectImage() {
      if (!this.isViewMode) {
        this.$refs.fileInput.click()
      }
    },
    adjustDecimalPlaces(field, places) {
      const num = this.user[field]

      if (num !== '') {
        this.user[field] = parseFloat(num.toString().replace('.', '').replace(',', '.')).toFixed(places)
      }
    },
  },
  setup() {
    const toast = useToast()

    const cep = {
      options: {
        cepDelimiter: {
          delimiters: ['-'],
          blocks: [5, 3],
          uppercase: true,
        },
      },
    }

    return {
      toast,
      cep,
      mdiLockOutline,
      mdiTrashCanOutline,
      mdiCash,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/base/core/colors/palette-variables.scss";

#permission_header {
  margin-top: 21px;
  margin-left: 21px;
  margin-bottom: 0px;
}

#idUserIcon {
  transition: box-shadow .3s;
  cursor: pointer;
  position: relative;
}

#idUserIcon:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

#idUserTrashBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1
}

#btnDeletePhoto {
  margin: 0.5rem;
  background-color: #ffffff;
}

#emptyUserPhoto {
  width: 10rem;
  height: 10rem;
  background-color: #EAEAEA;
  border-radius: 0.5rem;
  border: dashed 2px #005C96;
}

.card-title {
  color: $primary
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
