<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="refFormPassword"
  >
    <b-form
      ref="formPassword"
      @submit="handleSubmit(onSubmit)"
    >
      <b-card no-body>
        <b-card-title
          class="mt-2 ml-2 mb-0"
        >
          <svg-icon
            type="mdi"
            :path="path"
            class="mr-50"
          />
          <span class="d-inline">Senha</span>
        </b-card-title>
        <hr>
        <b-card-body
          class="pt-1"
        >
          <b-row>
            <!--Password Input Field -->
            <b-col md="6">
              <b-form-group>
                <label>Nova senha</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|password"
                  name="password"
                  vid="password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-new-password"
                      v-model="newPasswordValue"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      placeholder="Nova Senha"
                      @blur="refreshValidation"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--Repeat password must match  -->
            <b-col md="6">
              <b-form-group>
                <label>Confirme a nova senha</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|confirmed:password"
                  name="retype_password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      placeholder="Repita a Nova Senha"
                      @blur="refreshValidation"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-row>
        <b-col
          cols="12"
          class="buttonsSaveCancel"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1 d-none"
            type="submit"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, confirmed, password,
} from '@validations'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiLockOutline } from '@mdi/js'
import router from '@/router'
import syslic from '@/syslic'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    SvgIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
      confirmed,
      password,
      path: mdiLockOutline,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    newPasswordValue(val) {
      if (val === '' && this.RetypePassword === '') {
        this.$refs.refFormPassword.reset()
      }
    },
    RetypePassword(val) {
      if (val === '' && this.newPasswordValue === '') {
        this.$refs.refFormPassword.reset()
      }
    },
  },
  methods: {
    refreshValidation() {
      if (this.RetypePassword === '' && this.newPasswordValue === '') {
        this.$refs.refFormPassword.reset()
      }
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    updatePassword() {
      const promise = new Promise((resolve, reject) => {
        if ((this.newPasswordValue === '')
          && (this.RetypePassword === '')) {
          resolve()
        } else {
          this.$refs.refFormPassword.validate()
            .then(value => {
              if (!value) {
                reject()
              } else {
                return syslic.user.updateUserPassword(
                  router.currentRoute.params.id,
                  this.newPasswordValue,
                )
              }

              return new Promise((resolve_, reject_) => { reject_() })
            })
            .then(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Senha atualizada com sucesso.',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              resolve()
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Falha ao atualizar a senha.',
                  text:
                    'Não foi possível atualizar a senha, por favor entre em contato com o administrador do sistema.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  timeout: false,
                },
              })
              reject()
            })
        }
      })

      return promise
    },
    onSubmit(event) {
      event.preventDefault()
      this.updatePassword()
    },
  },
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
